<template>
    <div>
      <Pane />
  
      <a-card class="container">
        <a-row>
          <a-col :span="22">
            <a-form-model :colon="false" :model="form" layout="inline" @keyup.enter.native="query">
              <a-form-model-item>
                <a-input v-model="form.name" placeholder="标签名称" style="width: 150px"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-input v-model="form.code" placeholder="标签编码" style="width: 150px"></a-input>
              </a-form-model-item>
              <a-form-model-item>
                <a-space>
                  <a-button @click="query" type="primary">查询</a-button>
                  <a-button @click="reset">重置</a-button>
                </a-space>
              </a-form-model-item>
            </a-form-model>
          </a-col>
          <a-col :span="2">
            <div class="right" style="margin-top: 4px">
              <a-button
                @click="$router.push($route.path + '/add')"
                type="primary"
              >创建</a-button>
            </div>
          </a-col>
        </a-row>
  
        <Padding />
  
        <a-table
          bordered
          :data-source="list"
          :loading="loading"
          @change="onChange"
          :pagination="{
            total,
            current,
            pageSize,
            showTotal: (total) => `共 ${total} 条记录`,
          }"
        >
          <a-table-column title="标签名称" data-index="name"></a-table-column>
          <a-table-column title="标签编码" data-index="code" align="center"></a-table-column>
          <a-table-column title="人数" data-index="employeeNum" align="center"></a-table-column>
          <a-table-column title="描述" data-index="tagDesc" align="center"></a-table-column>
          <a-table-column title="创建人" data-index="creator" align="center"></a-table-column>
          <a-table-column title="状态" align="center">
            <template slot-scope="text">
                <span v-if="text.status == 1">
                    正常
                </span>
                <span v-else>
                    禁用
                </span>
            </template>
          </a-table-column>
  
          <a-table-column title="操作" align="center" width="140px">
            <template slot-scope="text">
              <a-space>
                <a
                  href="#"
                  @click.prevent="edit(text)"
                >编辑</a>
                <a
                  href="#"
                  @click.prevent="deleteText(text)"
                  class="danger"
                >删除</a>
              </a-space>
            </template>
          </a-table-column>
        </a-table>
      </a-card>
    </div>
  </template>
  
  
  <script>
  import watermark from "@/mixins/watermark";
  import { fetchList, remove} from "@/api/human-resources/tag";
  
  export default {
    mixins: [watermark],
  
    data() {
      return {
        form: {},
  
        loading: false,
        current: 1,
        pageSize: 10,
        list: [],
        total: 0
      };
    },
  
    mounted() {
      this.getList();
    },
  
    methods: {
      getList() {
        const { current, pageSize, form } = this;
        this.loading = true;
        fetchList({
          pageNum: current,
          pageSize: pageSize,
          ...form,
        })
          .then(res => {
            if (Array.isArray(res.list)) {
              this.list = Object.freeze(res.list);
              this.total = res.totalSize;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
  
      onChange(pagination) {
        this.current = pagination.current;
        this.pageSize = pagination.pageSize;
        this.getList();
      },
  
      query() {
        this.current = 1;
        this.getList();
      },
      reset() {
        this.form = {};
        this.current = 1;
        this.getList();
      },
  
      edit(text) {
        sessionStorage.setItem('tagDetail', JSON.stringify(text));
        this.$router.push(this.$route.path + "/edit");
      },
  
      deleteText(text) {
        const that = this;
        this.$confirm({
          title: "确定要删除此标签吗，已获得的标签会被同步回收",
          onOk() {
            remove(text.id).then(() => {
              that.getList();
            });
          }
        });
      }
    }
  };
  </script>